<template>
  <div class="navbarmobileFix">
    <div>
      <!-- <div style="transform: rotate(180deg)"> -->
      <nav class=" d-none d-lg-block three-nav1">
        <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
          <!-- <li class="three-nav1" @click="requestConsultation()">
          <div>
            <img src="~@/assets/images/hospitalplus.png" style="width: 40px;">
          </div>
        </li> -->
          <li class="three-nav1" @click="joinDoctorsCommunity()">
            <div>
              <img src="~@/assets/images/man-man.png" style="width: 40px;">
            </div>
          </li>
          <li class="three-nav1" @click="joinPatientGroups()">
            <div>
              <img src="~@/assets/images/community.png" style="width: 40px;">
            </div>
          </li>
          <li class="three-nav1" @click="accessReliableInformation()">
            <div>
              <img src="~@/assets/images/bookcreative.png" style="width: 40px;">
            </div>
          </li>
        </ul>
      </nav>
      <section class="banner-two2 img banner">
        <div class="container searchClinicTextHeading d-none d-lg-block">
          <h1 class="">Search for clinics that use RxIx</h1>
        </div>
        <div class="container searchClinicTextHeading d-block d-lg-none">
          <h1 class="mb-0">Search for clinics</h1>
          <h1>that use RxIx</h1>
        </div>
        <!-- FOR DESKTOP  -->
        <div class="container d-none d-lg-block text-align text-center banner-sub-content">
          <h3 class="mb-0">We are on a mission to enhance your experience</h3>
          <h3>when you consult doctors.</h3>
          <div class="mt-4">
            <h3 class="mb-0">It will take some time for us to reach out to multiple cities or to</h3>
            <h3 class="mb-0">every clinic, even within a city.</h3>
          </div>
          <div class="mt-4">
            <h3 class="mb-0">Meanwhile, please patronize the clinics listed here. They have</h3>
            <h3>chosen to use RxIx.</h3>
          </div>
        </div>
        <!-- FOR MOBILE  -->
        <div class="container d-block d-lg-none text-align text-center banner-sub-content searchClinicText">
          <h3 class="mb-0">We are on a mission to</h3>
          <h3 class="mb-0">enhance your experience</h3>
          <h3 class="mb-0">when you</h3>
          <h3 class="mb-0">consult doctors.</h3>

          <h3 class="mb-0 mt-4">It will take some time for us to</h3>
          <h3 class="mb-0">reach out to multiple cities or to</h3>
          <h3>every clinic, even within a city.</h3>

          <h3 class="mb-0 mt-4">Meanwhile, please patronize</h3>
          <h3 class="mb-0">the clinics listed here. They</h3>
          <h3 class="pb-1">have chosen to use RxIx.</h3>
        </div>
      </section>
      <section class="container banner-two2 img curve-searchclinic mb-5">
        <div class="container-fluid text-center text-align">
          <!-- FOR DESKTOP  -->
          <div class="d-none d-lg-block">
            <div>
              <h2 class="selectOptionText subselectOptionTop mb-0">Clinics that use RxIx</h2>
            </div>
            <!-- <div>
              <h2 class="selectOptionText subselectOptionTop mb-0">Sign up and invite the doctor you</h2>
              <h2 class="selectOptionText subselectOptionBottom">consult to use RxIx.</h2>
            </div> -->
          </div>
          <!-- FOR MOBILE  -->
          <div class="d-block d-lg-none">
            <div>
              <h2 class="selectOptionText subselectOptionTop mb-0">Clinics that use RxIx</h2>
              <!-- <h2 class="selectOptionText subselectOptionBottom">is near you?</h2> -->
            </div>
            <!-- <div>
              <h2 class="selectOptionText subselectOptionTop mb-0">Sign up and invite the</h2>
              <h2 class="selectOptionText subselectOptionBottom mb-0">doctor you consult to</h2>
              <h2 class="selectOptionText subselectOptionBottom">use RxIx.</h2>
            </div> -->
          </div>

          <!-- <div class="clinicFromDropDown">
            <div class="drp-city">
              <select name="select" id="select" :disabled="true">
                <option value="city">City</option>
              </select>
            </div>
            <div class="drp-select">
              <select name="select" id="select" :disabled="true">
                <option value="select">Select</option>
              </select>
            </div>
            <div class="go-btn">
              <button :disabled="true">Go</button>
            </div>
          </div> -->
          <div class="row box-items">
            <div class="col-opt" v-for="index in clinicConsult" :key="index"  @click="sign(index)">
              <div class="col-fix mt-3">
                <div class="btn-text">{{ index.clinic_name }}</div>
              </div>
              <div>
                <button class="btn btn-action">Clinic Consulation @  {{ index.pincode }}</button>
              </div>
            </div>
            <div class="col-opt" v-for="index in homeConsult" :key="index" @click="homeclinicsign(index)">
              <div class="col-fix mt-3" >
                <div class="btn-text">{{ index.clinic_name }}</div>
              </div>
              <div>
                <button class="btn btn-action">Home Consulation</button>
              </div>
            </div>
          </div>
          <div>
              <h2 class="selectOptionText subselectOptionTop mb-0">Do not find a clinic that is near you?</h2>
              <h2 class="selectOptionText subselectOptionBottom">Ask the doctor you consult to use RxIx.</h2>
            </div>
        </div>
      </section>
    </div>
  </div>
  <MobileNavbar></MobileNavbar>
</template>
<script>
import axios from "axios"
import MobileNavbar from "../common-mobile-footer.vue"
export default {
  components: {
    MobileNavbar
  },
  data() {
    return {
      // issign: false,
      seen: true,
      clinicConsult: [],
      homeConsult: []
      
    }
  },
  created: function () {
    this.getDataConsultation();
    this.currentRoute = this.$route.path.split('/')[2]
  },
  methods: {
    sign(index) {
      const clinicID =index.id
      const doctorId =index.hcps_id.id
      window.open(`/preview/clinic?hcpId=${doctorId}&clinicId=${clinicID}`)
    },
    homeclinicsign(index){
      const homeClinicName = index.id
      const doctorId =index.hcps_id.id
      window.open(`/preview/homeconsultation?hcpId=${doctorId}&homeId=${homeClinicName}`)
    },
 
    joinDoctorsCommunity() {
      window.scrollTo(0, 0);
      this.$router.push('/public/doctorcommunities');
    },
    joinPatientGroups() {
      window.scrollTo(0, 0);
      this.$router.push('/public/patientgroups');
        },
        accessReliableInformation() {
        window.scrollTo(0, 0);
        this.$router.push('/public/patientresources');
        },
        requestConsultation() {
        window.scrollTo(0, 0);
        this.$router.push('/public/requestconsultation');
    },

    getDataConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
        .then((response) => {
          this.userPayload = response.data
        })
      axios
        .get(`${BASE_API_URL}/home-consultation`)
        .then((response) => {


          this.homeConsult = response.data;
        })
      axios
        .get(`${BASE_API_URL}/clinic-consultation`)       
        .then((response) => {

            this.clinicConsult = response.data.filter((data)=>data.is_publish_clinic ===true);

          this.clinicName = this.clinicConsult[0].clinic_name;
        })
    }
  }
}
</script>
<style>
.row.box-items {
  margin-top: 40px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 25% 25% 25%;
  justify-content: center;
}

.col-opt {
  width: 100%;
  display: grid;
  justify-content: center;
}

.col-fix.mt-3 {
  cursor: pointer;
  flex-direction: column;
  border: 2px solid #00979e;
  width: 235px;
  height: auto;
  min-height: 115px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
}

.btn-text {
  text-align: center;
  padding: 15px;
  font-size: 22px;
}

button.btn.btn-action {
  background: #00979e;
  color: #fff;
  width: 235px;
  border-radius: inherit;
  padding: 0.375rem 0.75rem;
  /* font-size:20px; */
}

nav.three-nav1 {
  float: right;
  position: fixed;
  right: -3px;
  background: transparent;
}

nav.three-nav1:hover {
  transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-width:992px) {
  nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 0px
  }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
  position: relative;
  width: 71px;
  cursor: pointer;
  background: cadetblue;
  text-transform: uppercase;
  transition: all .4s ease-out;
  list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
  position: absolute;
  background: white;
  color: crimson;
  top: 0;
  left: 0px;
  width: 70px;
  height: 100%;
  opacity: .5;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: 0 100%;
  transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
  line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
  transform: translateX(0px);
  background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
  opacity: 1;
  transform: perspective(400px) rotateY(90deg) scale(1);
}

nav.three-nav1 ul.three-nav1 li>div {
  display: inline-block;
  padding: 25px 0;
  background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
  position: relative;
}

li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
  list-style: none;
  position: relative;
  left: 35px;
  bottom: 20px;
}

.banner-sub-content {
  margin-top: 20px;
}

.selectOptionText {
  color: #000;
  text-align: center;
}

.subselectOptionTop {
  margin-top: 3rem;
}

.subselectOptionBottom {
  margin-bottom: 3rem;
}

.clininDetailMain {
  margin-top: 40px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 22% 22% 22%;
  justify-content: center;
  width: 100%;
}

.clinicDetailSub {
  border: 2px solid #00979e;
  width: 206px;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
}

button.clinicDetailSub p {
  color: #000108;
  font-size: 23px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
}

button.clinicDetailSub h3 {
  color: #000108;
  font-weight: bold;
  font-size: 23px;
  margin-right: auto;
  margin-left: auto;
}

.drp-city select {
  background-color: #fff;
  color: #4d4d4d;
  border-color: #999999;
  width: 194px;
  height: 35px;
  border-radius: 8px;
  font-size: 21px;
}

.drp-select select {
  background-color: #fff;
  color: #4d4d4d;
  border-color: #999999;
  width: 194px;
  height: 35px;
  border-radius: 8px;
  font-size: 21px;
  margin-left: 44px;
  margin-right: 44px;
}

.go-btn button {
  color: #fff;
  background-color: #34989f;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: larger;
  border-radius: 12px;
  width: 72px;
  height: 37px;
  opacity: 0.7;
}

.clinicFromDropDown {
  display: flex;
  justify-content: center;
}

.searchClinicTextHeading h1 {
  text-align: center;
  color: #000000;
  font-weight: bold;
}

.searchClinicTextHeading {
  margin-top: 20px;
}

section.banner-two2.img.banner {
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/cards-banner7.png");
  background-size: 3500px 593px;
  /* transform: rotate(90deg);  */
  margin-top: 4.5rem;
  padding: 30px 0px 50px 0px;
}

section.banner-two2.img.-searchclinic {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}

@media screen and (max-width:1200px) {
  .row.box-items {
  grid-template-columns: 30% 30% 30%;

  }
}

@media screen and (max-width:992px) {
  .clininDetailMain {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .clinicDetailSub {
    width: 200px;
  }
  .row.box-items {
    grid-gap: 70px;
  }

}

@media screen and (max-width:991px) {
  .navbarmobileFix {
    margin-bottom: 7rem;
  }

  section.banner-two2.img.banner {
    margin-top: 0rem;
    padding: 30px 0px 35px 0px;
    background-size: 3500px 650px;
  }

  .searchClinicTextHeading h1 {
    font-size: 33px;
  }

  .searchClinicText h3 {
    font-size: 22px
  }

  .selectOptionText {
    font-size: 28px;
  }

  .subselectOptionTop {
    margin-top: 2rem;
  }

  .clinicFromDropDown {
    display: grid;
    grid-gap: 10px
  }

  .subselectOptionBottom {
    margin-bottom: 2rem;
  }

  .drp-city select {
    font-size: 23px;
  }
}

@media screen and (max-width:768px) {

  button.clinicDetailSub p {
    font-size: 16px;
  }

  button.clinicDetailSub h3 {
    font-size: 15px;
  }

  .row.box-items {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}

@media screen and (max-width: 620px) {
  .col-fix.mt-3 {
    width: 210px;
  }

  .btn-text {
    font-size: 20px;
  }

  button.btn.btn-action {
    font-size: 17px;
    width: 210px;
  }
}

@media screen and (max-width:576px) {
  .searchClinicTextHeading h1 {
    font-size: 25px;
  }

  .searchClinicText h3 {
    font-size: 17px
  }

  .selectOptionText {
    font-size: 21px;
  }

  .subselectOptionTop {
    margin-top: 1.5rem;
  }

  .drp-city select {
    font-size: 18px;
  }

  section.banner-two2.img.banner {
    padding: 10px 0px 15px 0px;
    background-size: 3500px 593px;
  }
}

@media screen and (max-width:550px) {

  .row.box-items {
    margin-top: 10px;
  }

}

@media screen and (max-width:470px) {
  .row.box-items {
    grid-gap: 1%
  }
  .clinicDetailSub {
    width: 135px;
    padding: 5px;
    margin: 5px;
  }

  .col-fix.mt-3 {
    width: 127px;
    min-height: 100px;
  }

  button.btn.btn-action {
    font-size: 12px;
    width: 127px;
  }

  .btn-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
  }
}</style>
